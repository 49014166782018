// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/providers/customer/provider.ts"
);
import.meta.hot.lastModified = "1730671429352.6575";
}
// REMIX HMR END

import {
  AddCustomersToGroupMutation,
  AddNoteToCustomerMutation,
  AddNoteToCustomerInput,
  UpdateCustomerNoteInput,
  UpdateCustomerNoteMutation,
  DeleteCustomerNoteMutation,  
  CreateAddressInput,
  CreateCustomerAddressMutation,
  CreateCustomerInput,
  CreateCustomerManagedGroupMutation,
  CreateCustomerMutation,
  CustomerAndGroupQuery,
  CustomerDetailAndGroupQuery,
  CustomerFormQuery,
  CustomerGroupListOptions,
  CustomerGroupQuery,
  CustomerGroupsQuery,
  CustomerListOptions,
  CustomerQuery,
  CustomerWithOrdersQuery,
  CustomersQuery,
  DeleteCustomerMutation,
  ListCustomerGroupAdminsQuery,
  OrderListOptions,
  RemoveCustomersFromGroupMutation,
  UpdateAddressInput,
  UpdateCustomerAddressMutation,
  UpdateCustomerGroupInput,
  UpdateCustomerGroupMutation,
  UpdateCustomerInput,
  UpdateCustomerMutation,  
} from '~admin/generated/graphql'
import { QueryOptions, sdk } from '~admin/graphqlWrapper'

export const transferCustomerToGroup = (
  sourceCustomerIds: string[],
  targetCustomerId: string,
  queryOptions?: QueryOptions,
) => {
  return sdk.transferToCustomerGroup(
    { sourceCustomerIds, targetCustomerId },
    queryOptions,
  )
}
export const listGroupAdmins = (
  search: string,
  options?: { take?: number },
  queryOptions?: QueryOptions,
): Promise<ListCustomerGroupAdminsQuery> => {
  return sdk.listCustomerGroupAdmins({ search, options }, queryOptions)
}

export const getCustomerAndGroup = (
  id: string,
  options?: QueryOptions,
): Promise<CustomerAndGroupQuery> => {
  return sdk.customerAndGroup({ id }, options)
}

export const getCustomerDetailAndGroup = (
  id: string,
  options?: QueryOptions,
): Promise<CustomerDetailAndGroupQuery> => {
  return sdk.customerDetailAndGroup({ id }, options)
}

export const filteredCustomerGroupMembers = (
  productVariantId: string,
  customerId: string,
  upgradeMode?: boolean,
  options?: QueryOptions,
) => {
  return sdk.filteredCustomerGroupMembers(
    { productVariantId, customerId, upgradeMode },
    options,
  )
}

export const addCustomersToGroup = (
  customerGroupId: string,
  customerIds: string[],
  options?: QueryOptions,
): Promise<AddCustomersToGroupMutation> => {
  return sdk.addCustomersToGroup({ customerGroupId, customerIds }, options)
}

export const removeCustomersFromGroup = (
  customerGroupId: string,
  customerIds: string[],
  options?: QueryOptions,
): Promise<RemoveCustomersFromGroupMutation> => {
  return sdk.removeCustomersFromGroup({ customerGroupId, customerIds }, options)
}

export const getCustomerGroup = (
  id: string,
  options?: QueryOptions,
): Promise<CustomerGroupQuery> => {
  return sdk.customerGroup({ id }, options)
}

export const getCustomerGroupList = (
  options?: CustomerGroupListOptions,
  queryOptions?: QueryOptions,
): Promise<CustomerGroupsQuery> => {
  return sdk.customerGroups({ options }, queryOptions)
}

export const addCustomerGroup = (
  customerId: string,
  options?: QueryOptions,
): Promise<CreateCustomerManagedGroupMutation> => {
  return sdk.createCustomerManagedGroup({ customerId }, options)
}

export const updateCustomerGroup = (
  id: string,
  input: Omit<UpdateCustomerGroupInput, 'id'>,
  options?: QueryOptions,
): Promise<UpdateCustomerGroupMutation> => {
  return sdk.updateCustomerGroup({ input: { id, ...input } }, options)
}

export const addAddress = (
  customerId: string,
  input: CreateAddressInput,
  options?: QueryOptions,
): Promise<CreateCustomerAddressMutation> => {
  return sdk.createCustomerAddress({ customerId, input }, options)
}

export const updateAddress = (
  input: UpdateAddressInput,
  options?: QueryOptions,
): Promise<UpdateCustomerAddressMutation> => {
  return sdk.updateCustomerAddress({ input }, options)
}

export const getWithOrders = (
  id: string,
  orderListOptions?: OrderListOptions,
  options?: QueryOptions,
): Promise<CustomerWithOrdersQuery> => {
  return sdk.customerWithOrders({ id, orderListOptions }, options)
}

export const get = (
  id: string,
  options?: QueryOptions,
): Promise<CustomerQuery> => {
  return sdk.customer({ id }, options)
}

export const getForm = (
  id: string,
  options?: QueryOptions,
): Promise<CustomerFormQuery> => {
  return sdk.customerForm({ id }, options)
}

export const add = (
  input: CreateCustomerInput,
  options?: QueryOptions,
): Promise<CreateCustomerMutation> => {
  return sdk.createCustomer({ input }, options)
}

export const update = (
  input: UpdateCustomerInput,
  options?: QueryOptions,
): Promise<UpdateCustomerMutation> => {
  return sdk.updateCustomer({ input }, options)
}

export const remove = (
  id: string,
  options?: QueryOptions,
): Promise<DeleteCustomerMutation> => {
  return sdk.deleteCustomer({ id }, options)
}

export const list = (
  options?: CustomerListOptions,
  queryOptions?: QueryOptions,
): Promise<CustomersQuery> => {
  return sdk.customers({ options }, queryOptions)
}

export const getCustomer = (
  id: string,
  options?: QueryOptions,
): Promise<CustomerQuery> => {
  return sdk.customer({ id }, options)
}

export const addNote = (
  input: AddNoteToCustomerInput,
  options?: QueryOptions,
): Promise<AddNoteToCustomerMutation> => {
  return sdk.addNoteToCustomer({ input }, options)
}

export const updateNote = (
  input: UpdateCustomerNoteInput,
  options?: QueryOptions,
): Promise<UpdateCustomerNoteMutation> => {
  return sdk.updateCustomerNote({ input }, options)
}

export const removeNote = (
  id: string,
  options?: QueryOptions,
): Promise<DeleteCustomerNoteMutation> => {
  return sdk.deleteCustomerNote({ id }, options)
}
